import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

class LearnInPublic extends React.Component {
  render() {
    return (
      <Layout location={this.props.location} title={"Blog"}>
        <SEO title="Peter Elbaum | Work" />
        <h1>🪓 Work</h1>
        <h2>Services</h2>
        <span>I'm currently open to working on a part-time, contractual basis with clients. Some things that I can do for you include:</span>
        <ul>
          <li>Building a website for your business</li>
          <li>Building custom software (web and mobile applications)</li>
          <li>Making your existing website faster</li>
          <li>Performing maintenance on your existing website/web app</li>
          <li>Consulting on technical feasibility of new business ideas and outlining a development roadmap</li>
          <li>Screening, interviewing and hiring technologists</li>
        </ul>

        <h2>Technologies</h2>
        <span>I've successfully delivered projects for clients using the following technologies:</span>
        <ul>
          <li>React/Redux</li>
          <li>React Native</li>
          <li>Angular</li>
          <li>CSS/SCSS/SASS/Styled Components</li>
          <li>Apollo GraphQL (client + server)</li>
          <li>Node.js</li>
          <li>RxJS</li>
          <li>Amazon Web Services</li>
          <li>PostgreSQL</li>
          <li>Sequelize</li>
        </ul>

        <h2>Rates</h2>
        <span>I consider freelance projects (and, accordingly, rates) a case-by-case basis. Please reach out to begin a conversation!</span>
        {/* <span>I'm currently learning the following:</span> 
        <ul style={{
          marginLeft: '20px'
        }}>
          <li>Docker</li>
          <li>AWS (Certified Solutions Architect exam)</li>
          <li>Figma/Procreate/Design</li>
          <li>Scripting/Automation</li>
        </ul> */}
      </Layout>
    )
  }
}

export default LearnInPublic
